// START  === Image
.img-header {
  background-image: url(../assets/image/pages-cover.png)
}

// photo === card//
.img-ads {
  background-image: url(../assets/image/ads-image.svg)
}
// home
.img-sections-requests {
  background-image: url(../assets/Icons/home/workers-laptob.webp)
}
.img-sections-responsive {
  background-image: url(../assets/Icons/home/workers-laptop-responsive.webp)
}

// person == card
.img.ads-content {
  background-image: url(../assets/image/ads-image2.svg)
}
// accountInfo
.img-account-info {
  background-image: url(../assets/Icons/regesteration/account-info.svg)
}

// START === Background Holder
.img-Background-Cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.img-Background-none {
  background-position: center;
  background-repeat: no-repeat;
  background-size: none
}

.img-Background-Contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

// END === BackgroundHolder
