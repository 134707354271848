// START === General
.cursor-click {
  cursor: pointer;
}

.uncursor-click {
  cursor: unset !important;
}
.text-Enter {
  white-space: pre-wrap;
}
.text-underline {
  text-decoration: underline;
}

// END === General
// START === Border Radius
.border-f-radius-s5 {
  border-radius: 5px;
}

.border-f-radius-s6 {
  border-radius: 6px;
}

.border-f-radius-s10 {
  border-radius: 10px;
}

.border-f-radius-s12 {
  border-radius: 12px;
}

.border-l-radius-s12 {
  border-radius: 12px 12px 0px 0px;
}

.border-l-radius-s6 {
  border-radius: 6px 6px 0px 0px;
}

.border-r-radius-s12 {
  border-radius: 0px 12px 12px 0px !important;
}

.border-b-radius-s12 {
  border-radius: 0px 0px 12px 12px;
}

.border-t-radius-s12 {
  border-radius: 12px 0px 0px 12px;
}

.border-f-radius-s15 {
  border-radius: 15px;
}

.border-R-radius-s15 {
  border-radius: 0px 15px 15px 0px;
}

.border-L-radius-s15 {
  border-radius: 15px 0px 0px 15px;
}

.border-f-radius-s16 {
  border-radius: 16px;
}

.border-B-radius-s20 {
  border-radius: 0px 0px 20px 20px;
}

.border-f-radius-s20 {
  border-radius: 20px;
}

.border-t-radius-s20 {
  border-radius: 20px 20px 0px 0px;
}

.border-l-radius-s20 {
  border-radius: 20px 0px 0px 20px;
}

.border-r-radius-s20 {
  border-radius: 0px 20px 20px 0px;
}

.border-f-radius-s28 {
  border-radius: 28px;
}

.border-f-radius-s50 {
  border-radius: 50%;
}

.border-l-radius-s50 {
  border-radius: 50% 0 0 50%;
}

.border-b-radius-s50 {
  border-radius: 0 0 50% 50%;
}

// END === Border Radius
// START === Gaps
.gap-8px {
  gap: 8px;
}

.gap-16px {
  gap: 16px;
}

.gap-24px {
  gap: 24px;
}

.gap-48px {
  gap: 48px;
}

// END === Gaps
// START === Border
.Border-dashed {
  border: 1px dashed #3B5976;
}

.Border-light-grey {
  border: 1px solid #E9ECEF;
}

.Border-light-green {
  border: 1px solid #1eaaad;
}

.Border-dark {
  border: 1px solid #495057;
}

.Border-b-grey-O50 {
  border-bottom: 1px solid rgba(112, 112, 112, 50%);
}

.Border-dark-grey {
  border: 1px solid rgba(112, 112, 112, 0.5);
}

.Border-grey {
  border: 1px solid #DEE2E6;
}

.Border-R-grey {
  border-right: 1px solid #DEE2E6;
}

.Border-L-grey {
  border-left: 1px solid #DEE2E6;
}

.Border-T-grey {
  border-top: 1px solid #DEE2E6;
}

.Border-B-grey {
  border-bottom: 1px solid #DEE2E6;
}

.Border-L-purple {
  border-left: 1px solid #657fbf;
}

.Border-purple {
  border: 2px solid #657fbf;
}

.Border-f-purple {
  border: 1px solid #657fbf;
}
.Border-f-white {
  border: 1px solid #fff;
}

.Border-f-purple-25 {
  border: 1px solid #3b597640;
  ;
}

.Border-f-purple {
  border: 1px solid #3b5976;
  ;
}

.Border-b-dark-grey {
  border-bottom: 1px solid #70707080;
}

.Border-b-grey-half {
  border-bottom: 0.5px solid #70707040;
}

.Border-f-dark {
  border: 1px solid rgba($color: #020304, $alpha: 0.1);
}

.Border-f-gray-dark {
  border: 1px solid #02030426;
}

.Border-f-red {
  border: 1px solid rgb(255, 0, 0);
}

// END === Border
//  START === boxShadow EDIT
.box-shadow {
  -webkit-box-shadow: 0px 4px 4px rgba(2, 3, 4, 0.08);
  box-shadow: 0px 4px 4px rgba(2, 3, 4, 0.08);
}

.box-F-shadow {
  -webkit-box-shadow: 4px 4px 4px 4px rgba(2, 3, 4, 0.08) !important;
  box-shadow: 4px 4px 4px 4px #02030414 !important;
}

//  END === boxShadow EDIT
//  START === input EDIT
input:focus-visible {
  outline: none !important;
}

textarea:focus-visible {
  outline: none !important;
}

// line-height
.line-height-15 {
  line-height: 1.5rem;
}

.line-height-2 {
  line-height: 2rem;

  @media (max-width: 768px) {
    line-height: 1.5rem;
  }
}

// opacity
.opacity-1 {
  opacity: 0.10000000149011612;
}
