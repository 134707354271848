// Start Font-Face
@font-face {
  font-family: Cairo-SemiBold;
  src: url("../assets/fonts/Cairo-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Cairo-Medium;
  src: url("../assets/fonts/Cairo-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: Cairo-Regular;
  src: url("../assets/fonts/Cairo-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Cairo-Bold;
  src: url("../assets/fonts/Cairo-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Cairo-Light;
  src: url("../assets/fonts/Cairo-Light.ttf");
  font-display: swap;
}
// END Font-Face
// font sizes [Cairo-Regular] === Start
.font-Regular-s10 {
  font-size: 10px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s12 {
  font-size: 12px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s14 {
  font-size: 14px !important;
  font-family: Cairo-Regular;
  @media (max-width: 768px) {
      font-size: 12px !important;
    }
}
.font-Regular-s16 {
  font-size: 16px !important;
  font-family: Cairo-Regular;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}
.font-Regular-s18 {
  font-size: 18px !important;
  font-family: Cairo-Regular;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}
.font-Regular-s20 {
  font-size: 20px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s22 {
  font-size: 22px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s24 {
  font-size: 24px !important;
  font-family: Cairo-Regular;
    @media (max-width: 768px) {
      font-size: 14px !important;
    }
}
.font-Regular-s28 {
  font-size: 28px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s32 {
  font-size: 32px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s36 {
  font-size: 36px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s40 {
  font-size: 40px !important;
  font-family: Cairo-Regular;
}
.font-Regular-s44 {
  font-size: 44px !important;
  font-family: Cairo-Regular;
}
// font sizes [Cairo-Regular] === End
// font sizes [Cairo-Bold] === Start
.font-Bold-s12 {
  font-size: 12px !important;
  font-family: Cairo-Bold;
}
.font-Bold-s14 {
  font-size: 14px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
      font-size: 12px !important;
    }
}
.font-Bold-s16 {
  font-size: 16px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}
.font-Bold-s18 {
  font-size: 18px !important;
  font-family: Cairo-Bold;
  @media (max-width: 810px) {
    font-size: 14px !important;
  }
}
.font-Bold-s20 {
  font-size: 20px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}
.font-Bold-s22 {
  font-size: 22px !important;
  font-family: Cairo-Bold;
}
.font-Bold-s24 {
  font-size: 24px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}
.font-Bold-s28 {
  font-size: 28px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
    font-size: 18px !important;
  }
}
.font-Bold-s32 {
  font-size: 32px !important;
  font-family: Cairo-Bold;
    @media (max-width: 768px) {
    font-size: 20px !important;
  }
}
.font-Bold-s36 {
  font-size: 36px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
      font-size: 22px !important;
    }
}
.font-Bold-s40 {
  font-size: 40px !important;
  font-family: Cairo-Bold;
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
}
.font-Bold-s44 {
  font-size: 44px !important;
  font-family: Cairo-Bold;
}
