@tailwind base;
@tailwind components;
@tailwind utilities;
// ejs-treeview
@import '~@ng-select/ng-select/themes/material.theme.css';
// angular material files
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/css-star-rating/scss/star-rating.scss';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// alert design
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1140px !important;
}

.CC-alert {
  color: red;
  font-size: 14px !important;
  font-family: Cairo-Regular;
}

textarea {
  resize: none;
}

// btn style
button:disabled,
button[disabled] {
  background-color: #8f8f8f !important;
  border: 1px solid #fff;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-width-holder:hover {
  background-color: rgba($color: #657FBF, $alpha: 0.8) !important;
  border-color: transparent !important;
}

.btn-holder:hover {
  background-color: rgba($color: #657FBF, $alpha: 0.8) !important;
  border-color: transparent !important;
}

// input style
textarea:focus:required:invalid {
  border: 1px solid red !important;
}

textarea:required:valid {
  border: 1px solid green !important;
}

input:focus:required:invalid {
  border: 1px solid red !important;
}

input:required:valid {
  border: 1px solid green !important;
}

input:focus-visible {
  outline: unset !important;
  outline-offset: unset !important;
}

input[type="checkbox"] {
  accent-color: #657fbf !important;
}
// scroll
::-webkit-scrollbar {
  width: 0px;
}



// edit switch button ,mat-slide-toggle

mat-slide-toggle {
  width: 45px !important;
  --mdc-switch-disabled-selected-handle-color: none !important;
  --mdc-switch-disabled-unselected-handle-color: none !important;
  --mdc-switch-disabled-selected-track-color: none !important;
  --mdc-switch-disabled-unselected-track-color: none !important;
  --mdc-switch-unselected-focus-state-layer-color: none !important;
  --mdc-switch-unselected-pressed-state-layer-color: none !important;
  --mdc-switch-unselected-hover-state-layer-color: none !important;
  --mdc-switch-unselected-focus-track-color: none !important;
  --mdc-switch-unselected-hover-track-color: none !important;
  --mdc-switch-unselected-pressed-track-color: none !important;
  --mdc-switch-unselected-track-color: none !important;
  --mdc-switch-unselected-focus-handle-color: none !important;
  --mdc-switch-unselected-hover-handle-color: none !important;
  --mdc-switch-unselected-pressed-handle-color: none !important;
  --mdc-switch-handle-surface-color: none !important;
  --mdc-switch-unselected-handle-color: none !important;
  --mdc-switch-selected-icon-color: none !important;
  --mdc-switch-disabled-selected-icon-color: none !important;
  --mdc-switch-disabled-unselected-icon-color: none !important;
  --mdc-switch-unselected-icon-color: none !important;
  --mdc-switch-selected-focus-state-layer-color: none !important;
  --mdc-switch-selected-handle-color: none !important;
  --mdc-switch-selected-hover-state-layer-color: none !important;
  --mdc-switch-selected-pressed-state-layer-color: none !important;
  --mdc-switch-selected-focus-handle-color: none !important;
  --mdc-switch-selected-hover-handle-color: none !important;
  --mdc-switch-selected-pressed-handle-color: none !important;
  --mdc-switch-selected-focus-track-color: none !important;
  --mdc-switch-selected-hover-track-color: none !important;
  --mdc-switch-selected-pressed-track-color: none !important;
  --mdc-switch-selected-track-color: none !important;
}

mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon,
mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none;
}

.pac-container {
  z-index: 10000;
}

// spinner style
::ng-deep .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
::ng-deep .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #fff !important;
}

::ng-deep .mat-mdc-progress-spinner {
  display: inline-block !important;
  overflow: hidden;
  line-height: 0;
  width: 55px !important;
  height: 25px !important;
}

::ng-deep .mat-progress-spinner {
  width: 100px !important;
  height: 18px !important;
}

::ng-deep .test {
  border: 1px solid #7BC950;
  background: rgba(245, 80, 76, 0.2);
  color: #7BC9501A;
  border-radius: 15px;
  font-size: 16px !important;
  font-family: Cairo-Bold !important;
  direction: rtl;
}

::ng-deep .mat-simple-snackbar {
  font-size: 14px !important;
  font-family: Cairo-Bold !important;
}

::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  direction: rtl;
}

::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: unset !important;
}

::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

::ng-deep .mdc-snackbar__label {
  padding-left: 16px;
  padding-right: 8px;
  width: 400px !important;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}

// hidden style of scrollbar
*::-webkit-scrollbar {
  width: 0px !important;
  height: 0px;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb {
  width: 0px !important;
  height: 0px;
  background-color: whitesmoke !important;
  border-radius: 0px !important;
}

*::-webkit-scrollbar:horizontal {
  width: 0px !important;
  height: 0px;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb:horizontal {
  width: 0px !important;
  height: 0px;
  background-color: whitesmoke !important;
  border-radius: 0px;
}
